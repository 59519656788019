import { useStyles } from '@team-griffin/stylr';
import { modifier, percentage } from '@ux/style-utils';
import { getTitleColor, getBodyColor } from 'presentation/utils/colorway';
import { gu2px } from '@ux/theme-utils';
import { Alignment } from 'domain/constants/alignment';
import * as r from 'ramda';
import { InnerProps } from './MessageStrip';
import { CSSProperties } from 'react';

export interface Styles {
  root: CSSProperties,
  container: CSSProperties,
  content: CSSProperties,
  title: CSSProperties,
  subtitle: CSSProperties,
  cta: CSSProperties,
  ctas: CSSProperties,
}
export default ({
  theme,
  colorway,
  isMinSm,
  layout,
  hasTitle,
}: InnerProps) => {
  const {
    spacing,
  } = theme;

  const {
    alignment,
  } = layout;

  return useStyles({
    default: {
      root: {
        display: 'block',
        textAlign: 'left',
        width: percentage(100),
      },
      container: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: percentage(100),
        // offset margin so content and title can be on same
        // line but wrap if needed and maintain margin between
        marginTop: r.negate(gu2px(3, spacing)),
      },
      content: {
        marginTop: gu2px(3, spacing),
      },
      title: {
        color: getTitleColor(theme, colorway),
      },
      subtitle: {
        color: getBodyColor(theme, colorway),
      },
      ctas: {
        marginTop: gu2px(2, spacing),
        width: percentage(100),
      },
      cta: {
        marginTop: gu2px(1, spacing),
      },
    },

    isMinSm: {
      ctas: {
        width: 'auto',
      },
      content: {
        marginRight: gu2px(3, spacing),
      },
    },

    noTitle: {
      subtitle: {
        marginTop: 0,
      },
    },

    [modifier('alignment', Alignment.CENTER)]: {
      root: {
        textAlign: 'center',
      },
      container: {
        alignItems: 'center',
        flexDirection: 'column',
      },
      content: {
        marginRight: 0,
      },
    },
  }, [
    { isMinSm },
    { alignment },
    { noTitle: !hasTitle },
  ]);
};
