import React from 'react';
import {
  withProps,
  defaultProps,
} from '@team-griffin/rehook';
import { Container } from '@ux/grid';
import PaddingBox from '@ux/padding-box';
import { Heading04, BodyCopy01 } from '@ux/typography';
import Shortcode, { ShortcodeProps } from 'components/Shortcode';
import Ribbon from 'components/ribbons/Ribbon';
import CallToAction from 'components/common/CallToAction';
import { BreakpointProps, withBreakpointsHook } from '@ux/responsive';
import { isNotNilOrEmpty } from 'crosscutting/ramda';
import visibilitySet from 'presentation/hocs/visibilitySet';
import errorBoundary from 'presentation/hocs/errorBoundary';
import { getAgainst } from 'presentation/utils/colorway';
import stylesheet, { Styles } from './MessageStrip.stylesheet';
import * as r from 'ramda';
import { RibbonProps } from 'domain/constants/ribbons';
import { CTA } from 'core/ribbons/cta';
import pureEnhance from '@ux/pure-enhance';

interface OuterProps extends RibbonProps {
  text: {
    title: ShortcodeProps,
    subtitle: ShortcodeProps,
  },
  ctas: CTA[],
}

interface DefaultProps extends OuterProps {
  baseline: number,
}
export interface InnerProps extends DefaultProps, BreakpointProps {
  styles: Styles,
  hasTitle: boolean,
  hasSubtitle: boolean,
  hasCta: boolean,
}
declare const i: number;
declare const cta: CTA;

export const PureMessageStrip = ({
  id,
  styles,
  theme,
  loading,
  layout,
  text,
  ctas,
  colorway,
  name,
  isMinSm,
  hasTitle,
  hasSubtitle,
  hasCta,
  hBaseline,
}: InnerProps) => {
  const { title, subtitle } = r.defaultTo({
    title: null,
    subtitle: null,
  }, text);

  return (
    <Ribbon
      id={id}
      theme={theme}
      colorway={colorway}
      layout={layout}
      loading={loading}
      flush={true}
      name={name}
      type="MessageStrip"
    >
      <div style={styles.root}>
        <Container>
          <PaddingBox theme={theme}>
            <div style={styles.container}>
              <div style={styles.content}>
                <If condition={hasTitle}>
                  <Heading04
                    theme={theme}
                    depth={hBaseline}
                    style={styles.title}
                  >
                    <Shortcode
                      theme={theme}
                      against={colorway}
                      code={title}
                    />
                  </Heading04>
                </If>
                <If condition={hasSubtitle}>
                  <BodyCopy01
                    theme={theme}
                    style={styles.subtitle}
                  >
                    <Shortcode
                      theme={theme}
                      against={colorway}
                      code={subtitle}
                    />
                  </BodyCopy01>
                </If>
              </div>
              <If condition={hasCta}>
                <div style={styles.ctas}>
                  <For
                    each="cta"
                    of={ctas}
                    index="i"
                  >
                    <div
                      key={i}
                      style={styles.cta}
                    >
                      <CallToAction
                        theme={theme}
                        against={getAgainst(colorway)}
                        ribbonId={id}
                        index={i}
                        block={r.not(isMinSm)}
                        {...cta}
                      />
                    </div>
                  </For>
                </div>
              </If>
            </div>
          </PaddingBox>
        </Container>
      </div>
    </Ribbon>
  );
};

export const enhance = pureEnhance<InnerProps, OuterProps>(
  'MessageStrip',
  {
    hocs: [
      errorBoundary,
      visibilitySet,
    ],
    hooks: [
      withBreakpointsHook,
      defaultProps({
        hBaseline: 1,
      }),
      withProps((ownerProps: DefaultProps) => ({
        // eslint-disable-next-line max-len
        hasTitle: r.pathSatisfies(isNotNilOrEmpty, [ 'text', 'title' ], ownerProps),
        hasSubtitle: r.pathSatisfies(isNotNilOrEmpty, [ 'text', 'subtitle' ], ownerProps),
        hasCta: isNotNilOrEmpty(ownerProps.ctas),
      })),
      withProps(stylesheet),
    ],
  },
);

export default enhance(PureMessageStrip);
